import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("产品名称"),
    dataIndex: "name",
    width: 240,
    ellipsis: true,
    sorter: true,
  },
  {
    title: T("产品编号"),
    dataIndex: "number",
    width: 180,
    ellipsis: true,
    sorter: true,
  },
  {
    title: T("产品条码"),
    dataIndex: "barcode",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("分类"),
    dataIndex: "category_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("状态"),
    dataIndex: "is_active",
    scopedSlots: { customRender: "is_active" },
    width: 80,
    ellipsis: true,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    width: 240,
    ellipsis: true,
  },
];
