import { getLodop } from "@/assets/LodopFuncs";
import JsBarcode from "jsbarcode";

export default function printLabel(items, labelWidth, labelHeight) {
  const LODOP = getLodop();
  LODOP.PRINT_INIT("Product Label");
  LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
  LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
  LODOP.SET_PRINT_PAGESIZE(1, `${labelWidth}mm`, `${labelHeight}mm`);

  for (const item of items) {
    runPrint(LODOP, item);
  }

  LODOP.PREVIEW();
}

function runPrint(LODOP, item) {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, item.barcode, {
    format: "CODE128",
    width: 3,
    height: 30,
    displayValue: true,
    background: "#ffffff",
    lineColor: "#000000",
    fontOptions: "bold",
    fontSize: 30,
  });
  const barcodeBase64 = canvas.toDataURL();
  const htmlText = `
<div style="padding: 1mm;">
  <table border="1" cellspacing="0" style="font-size: 16px; width: 100%; height: 100%; text-align: center">
    <tr>
      <td style="width: 10mm">
        <div>名称</div>
      </td>
      <td style="width: 55mm">${item.name ?? ""}</td>
      <td style="width: 10mm">
        单位
      </td>
      <td>${item.unit ?? ""}</td>
    </tr>
    <tr>
      <td style="width: 10mm">
        <div>规格</div>
      </td>
      <td colspan="3">${item.spec ?? ""}</td>
    </tr>
    <tr>
      <td colspan="4">
        <img src="${barcodeBase64}" />
      </td>
    </tr>
  </table>
</div>`;

  LODOP.NewPage();
  LODOP.ADD_PRINT_HTM("0%", "0%", "100%", "100%", htmlText);
}
